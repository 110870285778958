* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/* custom scrollbar */
.cardActions::-webkit-scrollbar {
  width: 20px;
}

.cardActions::-webkit-scrollbar-track {
  background-color: transparent;
}

.cardActions::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cardActions::-webkit-scrollbar-thumb:hover {
  background-color: #6a6866;
}