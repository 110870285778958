.inviteList {
  overflow-y: auto;
  background: rgb(100, 100, 100, 0.3);
  padding: 3px;
  max-height: 70vh;
  border-radius: 5px;

  .inviteCard {
    margin: 5px;
    padding: 5px;

    .inviteAvatar {
      margin-right: 7px;
    }
  }
}